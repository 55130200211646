<template>
  <Detail :isEdit="true" />
</template>

<script>
import Detail from "./components/Detail"
export default {
    name:'updateCategory',   
    components: {
        Detail
    }
}
</script>

<style>

</style>